import React from 'react';
import Layout from '../components/layout';
import { Helmet } from 'react-helmet';

export default ({ location }) => (
  <Layout location={location}>
    <Helmet title="Checkout" />
    <section className="px-10 py-10" style={{ minHeight: 'calc(100vh - 80px - 415px' }}>
      <h1>Checkout</h1>
    </section>
  </Layout>
);
